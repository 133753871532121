import log from "loglevel";
import {isFunction} from "lodash";

export default class Helpers {
  static convertModelToFormData(data = {}, form = null, namespace = "") {
    let files = {};
    let model = {};
    for (let propertyName in data) {
      if (
        data.hasOwnProperty(propertyName) &&
        data[propertyName] instanceof File
      ) {
        files[propertyName] = data[propertyName];
      } else {
        model[propertyName] = data[propertyName];
      }
    }

    let formData = form || new FormData();

    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || model[propertyName] == null)
        continue;
      let formKey = namespace ? `${namespace}.${propertyName}` : propertyName;
      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof File) {
        formData.append(formKey, model[propertyName]);
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          if (!(element instanceof File) && typeof element === "object")
            this.convertModelToFormData(element, formData, tempFormKey);
          else
            formData.append(
              tempFormKey,
              element instanceof File ? element : element.toString()
            );
        });
      } else if (
        typeof model[propertyName] === "object" &&
        !(model[propertyName] instanceof File)
      )
        this.convertModelToFormData(model[propertyName], formData, formKey);
      else {
        formData.append(formKey, model[propertyName].toString());
      }
    }

    for (let propertyName in files) {
      if (files.hasOwnProperty(propertyName)) {
        formData.append(propertyName, files[propertyName]);
      }
    }
    return formData;
  }

  static ToLatLon(coordinate) {
    return coordinate && { lat: coordinate.lat, lon: coordinate.lng };
  }

  static ToLatLng(coordinate) {
    return coordinate && { lat: coordinate.lat, lng: coordinate.lon };
  }

  static proxied(base, path) {
    return /*process.env.NODE_ENV === "development" ? path : */base + path;
  }
}

export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    log.warn(err);
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    log.warn(err);
    // ignore write errors
  }
};


export const suspend = (promise) => {
  let result;
  let status = 'pending';
  const suspender = (isFunction(promise) ? promise() : promise).then(response => {
    status = 'success';
    result = response;
  }, error => {
    status = 'error';
    result = error;
  });

  return {
    read: () => {
      switch (status) {
        case 'pending':
          throw suspender;
        case 'error':
          throw result;
        default:
          return result;
      }
    }
  };
};