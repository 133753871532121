let config;

export const init = () =>
    fetch("/env.json", {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(r => {
        //console.log({r}, r.ok, r.error);
        if(r.ok)
            return r.json();
        throw r.error;
    })
        .then(json => {
            config = json;
            console.log({config});
            return config;
        });

const get = () => config;
export default get;