import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-xhr-backend";
import detector from "i18next-browser-languagedetector";
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/fr";
import numeral from "numeral";
import countries from "i18n-iso-countries";

import enCountries from "i18n-iso-countries/langs/en.json";
import frCountries from "i18n-iso-countries/langs/fr.json";

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    },
    //lng: "en",
    fallbackLng: "en",
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        if (format.indexOf("bytes-") === 0) {
          const subFormat = format.substr("bytes-".length);
          return numeral(value).format(subFormat);
        }
        if (format.indexOf("intl-number") === 0) {
          const { value: v, options } = value;
          return Intl.NumberFormat(lng, options).format(v);
        }
        if (format.indexOf("date-") === 0) {
          const subFormat = format.substr("date-".length);
          return moment(value).format(subFormat);
        }
        return value;
      }
    }
  });

i18n.on("languageChanged", lng => {
  console.log("lang", { lng });
  Moment.globalLocale = lng;
  numeral.locale(lng);
});

countries.registerLocale(enCountries);
countries.registerLocale(frCountries);

export const getAvailableCountriesLocale = () => {
  const lang = i18n.language.split("-")[0];
  return ["fr", "en"].includes(lang) ? lang : "en";
};

export const getCountries = () =>
  Object.entries(countries.getNames(getAvailableCountriesLocale())).filter(
    ([code]) => code !== "EH"
  );

export default i18n;
