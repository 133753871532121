import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {from} from "rxjs";
import "moment-timezone";
import log from "loglevel";

import "./i18n";

import "@openfonts/exo_all";

import {setObservableConfig} from "recompose";
import {pdfjs} from "react-pdf";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./css/react_dates_overrides.css";
import {suspend} from "./Helpers";
import EnvConf, {init as initEnvConf} from "./EnvConf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

setObservableConfig({
  fromESObservable: from
});

const setupStore = async () => {
    log.setLevel(EnvConf().logLevel || "warn");
    const store = await import("./store");
    const handler = await import("./request-error");
    handler.default.setup(store.default);
}

const fetchEnv = suspend(initEnvConf().then(_ => setupStore()));

const WithEnv = ({children}) => {
    fetchEnv.read();
    return children;
}

const Main = React.lazy(() => import("./Main"));
const root = (
    <Suspense fallback={null}>
        <WithEnv>
            <Main/>
        </WithEnv>
    </Suspense>
);

ReactDOM.render(root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
